import React, {Component} from 'react'
import {connect} from 'react-redux'
import PT from 'prop-types'
import Helmet from 'react-helmet'
import DarkMask from './components/DarkMask'

class App extends Component {
  componentDidMount() {
    // app 级别的公用逻辑可以放到这里
    // 比如白天、夜间模式功能
  }

  render() {
    return (
      <div className="AppMain">
        <Helmet>
          <title />
        </Helmet>
        {this.props.children}
        <DarkMask />
      </div>
    )
  }
}

App.propTypes = {
  children: PT.node.isRequired,
}

export default connect()(App)
