import {combineReducers} from 'redux'

import deviceinfo from './deviceinfo'
import env from './env'
import userInfo from './userInfo'
import wallet from './wallet'
import withdraw from './withdraw'
import vip from './vip'
import recharge from './recharge'
import share from './share/index'
import lh from './lh'

export default combineReducers({
  deviceinfo,
  env,
  userInfo,
  wallet,
  withdraw,
  vip,
  recharge,
  share,
  lh,
})
