import 'babel-polyfill'
import 'whatwg-fetch'
import React from 'react'
import {hydrate} from 'react-dom'
// import {BrowserRouter} from 'react-router-dom'
import {Router} from 'react-router'
import {renderRoutes} from 'react-router-config'
import {Provider} from 'react-redux'
// import Raven from 'raven-js'
import once from 'lodash/once'
import Loadable from 'react-loadable'
import 'normalize.css/normalize.css'
import history from './history'
import '../utils/pwBridge'
import '../utils/flexible'
import getRoutes from '../routes'
import PendingNavDataLoader from './PendingNavDataLoader'
import '../styles/init.scss'
import createStore from '../store'
import {setDeviceInfo} from '../reducers/deviceinfo'
import {getDeviceInfoII} from '../utils/pwHybrid'
import App from '../App'

const initDeviceinfo = async store => {
  const {deviceinfo} = store.getState()

  if (deviceinfo && deviceinfo.h_app) {
    return
  }

  const info = await getDeviceInfoII()
  store.dispatch(setDeviceInfo(info))
}

const initApp = async () => {
  const store = createStore({}, window.APP_INITIAL_STATE)
  await initDeviceinfo(store)
  const routes = getRoutes(store)
  Loadable.preloadReady().then(() => {
    hydrate(
      <Provider store={store}>
        <Router history={history}>
          <PendingNavDataLoader store={store} routes={routes}>
            {() => <App>{renderRoutes(routes)}</App>}
          </PendingNavDataLoader>
        </Router>
      </Provider>,
      document.getElementById('app')
    )

    const ele = document.querySelector('#appState')
    ele && ele.parentNode.removeChild(ele)
    window.APP_INITIAL_STATE = null
    window.store = store
  })
}

const initVConsole = () =>
  new Promise(resolve => {
    if (
      {
        development: true,
        test: true,
        production: false,
      }[ENVIRONMENT]
    ) {
      const script = document.createElement('script')
      script.onload = () => {
        if (window.VConsole) {
          // eslint-disable-next-line no-new
          new window.VConsole()
          resolve()
        }
      }
      // script.src = 'https://cdn.bootcss.com/vConsole/3.2.0/vconsole.min.js'
      script.src =
        'https://lostradio-hangzhou.oss-cn-hangzhou.aliyuncs.com/misc/vconsole@3.2.0.min.js'
      document.head.appendChild(script)
    } else {
      resolve()
    }
  })

/*
const initRaven = () => {
  if (ENVIRONMENT === 'production' || ENVIRONMENT === 'test') {
    Raven.config(
      'https://cea39af662954232b69f92f05f12af2b@sentry.iniucha.com/33',
      {
        release: RELEASE,
        captureUnhandledRejections: true,
        maxUrlLength: 1000, // https://github.com/getsentry/sentry-javascript/pull/906/files
      }
    ).install()
  }
}
*/

const init = once(async () => {
  // initRaven()
  await initVConsole()
  initApp()
})

if (document.readyState === 'complete') {
  init()
} else {
  document.onreadystatechange = () => {
    if (document.readyState === 'complete') {
      // https://developer.mozilla.org/zh-CN/docs/Web/API/Document/readyState
      init()
    }
  }

  window.addEventListener('load', init)
}
