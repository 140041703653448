import Loadable from 'react-loadable'

export default [
  {
    path: '/mobile/upload',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-WithdrawRule" */ '../pages/mobile/uploadAudio'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/billboard/test_entry',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-BillboardTestEntry" */ '../pages/billboard/testEntry'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/billboard',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-Billboard" */ '../pages/billboard'),
      loading: () => null,
    }),
  },
]
