export const isMobile = userAgent =>
  /(iphone|ipad|ipod|android|lihan_ad)/i.test(userAgent)
export const isChrome = userAgent => /chrome/i.test(userAgent)
export const isIOS = userAgent => /(iphone|ipad|ipod)/i.test(userAgent)
export const isAndroid = userAgent => /(android|lihan_ad)/i.test(userAgent)
export const isWX = userAgent => /micromessenger/i.test(userAgent)
export const isWXPc = userAgent => /(WindowsWechat|MacWechat)/.test(userAgent)
export const isWXMini = userAgent => /miniProgram/i.test(userAgent)
export const isQQ = userAgent => /\sQQ\//i.test(userAgent)
export const isTimQQ = userAgent =>
  /\sQQ\//i.test(userAgent) && /TIM\//i.test(userAgent)
export const isWB = userAgent => /weibo/i.test(userAgent)
export const isYD = userAgent => /youdian/i.test(userAgent)
export const isPW = userAgent => /piwan|peiwan|niule/i.test(userAgent)
export const isDA = (hostname = '') =>
  hostname === 'h5.iniucha.com' ||
  hostname === 'iniucha.cn' ||
  hostname.endsWith('.iniucha.cn')
export const isX = (hostname = '') =>
  hostname.endsWith('.x.iniucha.com') ||
  (hostname !== 'h5.iniucha.com' &&
    (hostname === 'iniucha.com' || hostname.endsWith('.iniucha.com'))) ||
  ENVIRONMENT === 'development'
export const isLH = (userAgent, hostname = '') =>
  /APICloud/i.test(userAgent) ||
  hostname === 'lihan.ltd' ||
  hostname.endsWith('.lihan.ltd')
export const isAPICloud = userAgent => /APICloud/i.test(userAgent)
export const isSD = (hostname = '') => hostname === 'h5.sd.huk.ltd'
